@import "~antd/dist/reset.css";

#root {
  height: 100%;
  width: 100%;
}

.exam-card {
  width: 100%;
  height: 100%;
  overflow: auto;
}

@media (min-width: 992px) {
  .menu {
    display: none;
  }
  .exam-card {
    width: 320px;
  }
  #exam-cards-container {
    width: 992px;
    margin: 0 auto;
    justify-content: center;
  }
}

.ant-table-thead > tr > th,
.ant-table-tbody > tr > td {
  padding: 10px 6px;
}

.ant-table table {
  font-size: 15px;
}

.ant-card-bordered{
  margin-bottom: 8px;
}

.main-layout-content,
.visit-layout-content {
  background-color: #fff;
}

.main-layout-inner-content2 {
  padding: 20px;
}

.main-layout {
  height: 100%;
}

.main-layout-header {
  background: #e6f7ff;
  text-align: center;
}

.main-layout-header h2{
  color: #1890ff;
}

.table-header {
  font-size: 12px;
}

.selected-row {
  font-size: 12px;
  background: #e6f7ff;
}

.ant-avatar.ant-avatar-icon > .anticon {
  margin-top: 5px;
}

.ant-card-actions {
  border: none!important;
}

.ant-page-header {
  padding: 0!important;
}

.ant-list-item-action {
  margin-top: auto;
}

.ant-input-textarea {
  width: 100%;
}

.ant-modal-close-x {
  margin-top: 5px;
}

.ant-modal-body {
  max-height: 500px;
  overflow-y: auto;
}

/*.ant-tree-switcher {*/
/*  display: none!important;*/
/*}*/

/*.ant-tree .ant-tree-node-content-wrapper .ant-tree-iconEle {*/
/*  display: none;*/
/*}*/

.ant-tree-title selected {
  color: red;
}

.ant-list-item {
  padding: 12px!important;
}

.ant-list-item-meta-content {
  width: 100%;
}

.ant-card-actions > li {
  text-align: left;
  margin: 12px 12px;
}

.ant-tree .ant-tree-node-content-wrapper {
  padding: 0!important;
  margin-top: -4px;
}
.ant-tree-indent {
  margin-left: -15px;
}

input {
  padding: 0.5rem;
  font-size: 16px;
  width: 100%;
  display: block;
  border-radius: 4px;
  border: 1px solid #ccc;
}

input:focus {
  border-color: #007eff;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 3px rgba(0, 126, 255, 0.1);
  outline: none;
}

input.error {
  border-color: red;
}

.input-feedback {
  color: red;
  margin-top: 0.25rem;
}

.ant-badge-status-dot {
  width: 10px;
  height: 10px;
}

.loadingWrap {
  position: absolute;
  margin: auto;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 500px;
  height: 50px;
  text-align: center;
}

.loadingBoxes {
  display: inline-block;
  margin: 3px;
  width: 40px;
  height: 40px;
  border-radius: 10px;
}

.loadingBoxColour1 {background: #89d1d1;
  animation: loadingBoxColour; animation-duration: 2s;
  animation-delay: 0.0s; animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
}
.loadingBoxColour2 {background: #00b0ad;
  animation: loadingBoxColour; animation-duration: 2s;
  animation-delay: 0.1s; animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
}
.loadingBoxColour3 {background: #00908e;
  animation: loadingBoxColour; animation-duration: 2s;
  animation-delay: 0.2s; animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
}
.loadingBoxColour4 {background: #027371;
  animation: loadingBoxColour; animation-duration: 2s;
  animation-delay: 0.3s; animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
}

.loadingBoxColour5 {background: #00706e;
  animation: loadingBoxColour; animation-duration: 2s;
  animation-delay: 0.4s; animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
}

@keyframes loadingBoxColour {
  0% { transform: scale(1.0); }
  50% { transform: scale(0.5); }
  100% { transform: scale(1.0); }
}


